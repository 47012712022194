/* @refresh reload */
import { lazy } from "solid-js"

import { Router, Routes, Route } from "@solidjs/router"

import Sidebar from "@/views/Sidebar"
const Converter = lazy(() => import("@/views/Converter"))
const CuesEditor = lazy(() => import("@/views/CuesEditor"))
const About = lazy(() => import("@/views/About"))

const App = () => {
  return (
    <>
      <Router>
        <main class="layout">
          <Sidebar />
          <Routes>
            <Route path="/" component={Converter} />
            <Route path="/cues" component={CuesEditor} />
            <Route path="/about" component={About} />
          </Routes>
        </main>
      </Router>
    </>
  )
}

export default App
