/* @refresh reload */
import { render } from 'solid-js/web';

import './index.css';
import App from './views/App';

import * as Sentry from "@sentry/browser";

if (import.meta.env.PROD && import.meta.env.VITE_SENTRY === 'true') {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        tunnel: "/backend/convert/bugs",

        // Performance Monitoring
        tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),

        // Session Replay
        replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
        // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        replaysOnErrorSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE), 
        integrations: [new Sentry.Replay()],
    });
}

render(App, document.getElementById('root') as HTMLElement);
